import { Injectable, Injector } from '@angular/core';

import { AuthService } from 'src/app/core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  constructor(private injector: Injector) {}

  load(): Promise<boolean> {
    const authService = this.injector.get(AuthService);

    return new Promise((resolve, reject) => {
      authService.recoverSession().subscribe(
        () => resolve(true),
        () => resolve(true)
      );
    });
  }
}
