export class LocaleKeys {
  static accessToken = 'BONSALAIRE_PWD_accessToken';
  static authProfile = 'BONSALAIRE_PWD_authProfile';

  static rememberMe = 'BONSALAIRE_PWD_rememberMe';
  static rememberMePassword = 'BONSALAIRE_PWD_rememberMe_password';
  static rememberMeEmail = 'BONSALAIRE_PWD_rememberMe_email';

  static userAdress = 'BONSALAIRE_SAVE_userAddress';

  static cookiesAccepted = 'BONSALAIRE_COOKIES_accepted';
}
