import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticateGuard } from './core/guards/authenticate.guard';
import { GuestGuard } from './core/guards/guest.guard';

const routes: Routes = [
  {
    path: 'app',
    loadChildren: () =>
      import('./modules/logged-area/logged-area.module').then((m) => m.LoggedAreaModule),
    canActivate: [AuthenticateGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/public-area/public-area.module').then((m) => m.PublicAreaModule),
    canActivate: [GuestGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
