export const environment = {
  production: true,
  version: '1.1.1',
  apiUrl: 'https://api.bonsalaire.com',
  publicUrl: 'https://bonsalaire.com',
  locale: 'fr-FR',
  encryptSalt: 'iirrogzrtgeqeybhr7k98imwfcs52g',
  stripeApiKey:
    'pk_live_51J7MLcA0iHACF7Bz8WBCvBH1am3peqriWxJlKalrLR1T27nwbeGjRl7Rjjlr7fyU1hguTAi9LdEzehjk5sQA8aJQ00YCDG08tm',
};
