import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { throwError, Observable } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

import { environment } from './../../../environments/environment';

import { AuthService } from 'src/app/core/services/auth.service';

const TIME_OUT_DELAY = 120 * 1000;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      this.authService.isStillLoggedIn() &&
      this.authService.accessToken &&
      request.url.indexOf(environment.apiUrl) !== -1
    ) {
      request = this.addToken(request, this.authService.accessToken);
    }

    return next.handle(request).pipe(
      timeout(TIME_OUT_DELAY),
      catchError((error: any) => {
        if (error.error.code !== 401) {
          console.error(error);
        }
        return throwError(error);
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
