import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import { LoginResult, UpdateUserPayload } from 'src/app/shared/models/auth.model';
import { BsUser } from 'src/app/shared/models/bs-user.model';

import { HttpAuthService } from './http/http-auth.service';

import { LocaleKeys } from 'src/app/shared/utils/locale-keys';

export let LOGIN_STATE = {
  ATTEMPT_TO_RECOVER: 'ATTEMPT_TO_RECOVER',
  IDLE: 'IDLE',
  LOGGED: 'LOGGED',
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedUser = new BehaviorSubject<BsUser>(null);
  loginState = new BehaviorSubject<string>(LOGIN_STATE.ATTEMPT_TO_RECOVER);

  accessToken: string;

  constructor(private httpAuthService: HttpAuthService, private router: Router) {}

  login(email: string, password: string) {
    this.cleanAuthLocalStorage();

    return this.httpAuthService.login(email, password).pipe(
      tap((res) => {
        this.setSession(res);
      }),
      concatMap(() => {
        return this.loadUserProfile();
      }),
      tap(() => {
        this.userIsLogged();
      })
    );
  }

  logout() {
    this.router.navigate(['/user/login']);

    this.cleanAuthLocalStorage();

    this.loginState.next(LOGIN_STATE.IDLE);
    this.loggedUser.next(null);

    this.accessToken = null;
  }

  userIsLogged() {
    this.loginState.next(LOGIN_STATE.LOGGED);
  }

  private setSession(loginResult: LoginResult) {
    this.accessToken = loginResult.token;
    localStorage.setItem(LocaleKeys.accessToken, this.accessToken);
  }

  variablesInSessionExists() {
    return localStorage.getItem(LocaleKeys.accessToken);
  }

  isStillLoggedIn() {
    return this.variablesInSessionExists();
  }

  recoverSession() {
    if (this.variablesInSessionExists()) {
      return this.recoverStorageVariables().pipe(
        tap((res) => {
          this.userIsLogged();
        }),
        catchError((err) => {
          this.logout();
          return throwError(err);
        })
      );
    } else {
      this.loginState.next(LOGIN_STATE.IDLE);
      return of(true);
    }
  }

  private recoverStorageVariables() {
    this.accessToken = localStorage.getItem(LocaleKeys.accessToken);
    return this.loadUserProfile().pipe(
      map((res) => {
        if (res) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  private loadUserProfile() {
    return this.httpAuthService.getUserProfile().pipe(
      tap((res) => {
        this.setUserProfile(res);
      })
    );
  }

  updateUser(data: UpdateUserPayload) {
    return this.httpAuthService.updateUser(this.loggedUserValue.id, data).pipe(
      concatMap((res) => {
        return this.loadUserProfile();
      })
    );
  }

  private setUserProfile(userProfile: BsUser) {
    this.loggedUser.next(userProfile);
    localStorage.setItem(LocaleKeys.authProfile, JSON.stringify(userProfile));
  }

  private cleanAuthLocalStorage() {
    this.accessToken = null;
    localStorage.removeItem(LocaleKeys.accessToken);
    localStorage.removeItem(LocaleKeys.authProfile);
  }

  get loggedUserValue() {
    return this.loggedUser.getValue();
  }
}
