import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { LoginResult, RegisterPayload, UpdateUserPayload } from 'src/app/shared/models/auth.model';
import { BsUser } from 'src/app/shared/models/bs-user.model';
import { HydraMessage } from './../../../shared/models/hydra.model';

@Injectable({
  providedIn: 'root',
})
export class HttpAuthService {
  constructor(private http: HttpClient) {}

  login(email: string, password: string) {
    return this.http
      .post(`${environment.apiUrl}/authenticate`, {
        email,
        password,
      })
      .pipe(
        map((res) => {
          return res as LoginResult;
        })
      );
  }

  getUserProfile() {
    return this.http.get(`${environment.apiUrl}/users/me`).pipe(
      map((res) => {
        return new BsUser(res);
      })
    );
  }

  register(registerCredentials: RegisterPayload) {
    return this.http.post(`${environment.apiUrl}/users`, registerCredentials).pipe(
      map((res) => {
        return new BsUser(res);
      })
    );
  }

  validateAccount(token: string) {
    return this.http.patch(`${environment.apiUrl}/users/${token}/validate`, null).pipe(
      map((res) => {
        return res as HydraMessage;
      })
    );
  }

  forgotPassword(email: string) {
    return this.http.post(`${environment.apiUrl}/forgot-password`, {
      email,
    });
  }

  resetPassword(token: string, password: string) {
    return this.http.post(`${environment.apiUrl}/forgot-password/${token}`, {
      password,
    });
  }

  updateUser(userId: number, data: UpdateUserPayload) {
    return this.http.put(`${environment.apiUrl}/users/${userId}`, data);
  }

  resendActivationEmail(email: string) {
    return this.http.post(`${environment.apiUrl}/users/resend-mail-validate`, {
      email,
    });
  }
}
