import { Component, OnInit } from '@angular/core';

import { locale } from 'moment';

import { environment } from 'src/environments/environment';

import { RequestService } from 'src/app/core/services/request.service';
import { AngularticsService } from './core/services/angulartics.service';
import { VersionService } from './core/services/version.service';

import { LocaleKeys } from './shared/utils/locale-keys';

locale(environment.locale);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  appIsLoading = true;

  environment = environment;

  constructor(
    public versionService: VersionService,
    private angularticsService: AngularticsService,
    private requestService: RequestService
  ) {
    this.angularticsService.init();
    this.requestService.init();
  }

  ngOnInit() {}

  showCookies() {
    return localStorage.getItem(LocaleKeys.cookiesAccepted) !== '1';
  }

  acceptCookies() {
    localStorage.setItem(LocaleKeys.cookiesAccepted, '1');
  }
}
