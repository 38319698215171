import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  LOCALE_ID,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { Angulartics2Module } from 'angulartics2';
import { DateFormatPipe } from 'ngx-moment';
import { ToastrModule } from 'ngx-toastr';

import { environment } from 'src/environments/environment';

import { TokenInterceptor } from './interceptors/token.interceptor';
import { StartupService } from './services/startup.service';

function initConfiguration(startupService: StartupService) {
  return () => startupService.load();
}
@NgModule({
  imports: [
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    Angulartics2Module.forRoot(),
    AngularSvgIconModule.forRoot(),
  ],
  providers: [
    StartupService,
    DateFormatPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfiguration,
      multi: true,
      deps: [StartupService],
    },
    {
      provide: LOCALE_ID,
      useValue: environment.locale,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() private parent: CoreModule) {
    if (parent) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [],
    };
  }
}
