import { Injectable } from '@angular/core';

import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';

@Injectable({
  providedIn: 'root',
})
export class AngularticsService {
  constructor(
    private angulartics2GoogleGlobalSiteTag: Angulartics2GoogleGlobalSiteTag,
    private angulartics2: Angulartics2
  ) {}

  init() {
    this.angulartics2GoogleGlobalSiteTag.startTracking();
  }

  sendEvent(action: string, category: string, label: string) {
    this.angulartics2.eventTrack.next({
      action,
      properties: {
        category,
        label,
      },
    });
  }
}
