import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { BsRequest } from 'src/app/shared/models/bs-request.model';

import { AuthService, LOGIN_STATE } from './auth.service';
import { HttpRequestService } from './http/http-request.service';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  requestsList$ = new BehaviorSubject<BsRequest[]>(null);

  constructor(
    private router: Router,
    private authService: AuthService,
    private httpRequestService: HttpRequestService
  ) {}

  init() {
    this.authService.loginState.subscribe((loginState) => {
      if (loginState === LOGIN_STATE.LOGGED) {
        this.updateRequestsList().subscribe();
      } else {
        this.requestsList$.next(null);
      }
    });
  }

  updateRequestsList() {
    return this.httpRequestService.query().pipe(
      tap((res) => {
        this.requestsList$.next(res['hydra:member']);
      })
    );
  }

  getRequestsList() {
    return this.requestsList$.pipe(
      filter((res) => {
        return res !== null;
      })
    );
  }

  goToRequest(request: BsRequest) {
    if (request.isDraft) {
      if (!request.files.length) {
        this.router.navigate(['/app/user-flow/download', request.id]);
      } else {
        this.router.navigate(['/app/user-flow/informations', request.id]);
      }
    } else {
      if (request.status < 3) {
        this.router.navigate(['/app/user-flow/report', request.id]);
      } else {
        this.router.navigate(['/app/user-flow/recuperation', request.id]);
      }
    }
  }
}
