<router-outlet></router-outlet>

<div class="fixed right-2 bottom-2 text-10 italic text-text-light z-panel">
  v{{ versionService.version }}
</div>

<div class="block--rgpd" *ngIf="showCookies()">
  <div class="block--rgpd__mask"></div>
  <div class="block--rgpd__content">
    <div class="base-container">
      <div class="block--rgpd__left">
        <div class="block--rgpd__logo">
          <img src="assets/images/logo/logo-bonsalaire.svg" alt="bon salaire" />
        </div>
        <div class="text-28 font-semibold mb-12">
          Le respect de votre vie privée est notre priorité
        </div>
        <div class="block--text text-content">
          Nous utilisons différentes technologies, telles que les cookies, pour personnaliser les
          contenus, proposer des fonctionnalités sur les réseaux sociaux et analyser le trafic.
          Merci de cliquer sur le bouton ci-dessous pour donner votre accord. Vous pouvez changer
          d’avis et modifier vos choix à tout moment.
        </div>
      </div>
      <div class="block--rgpd__right">
        <div class="btn btn-primary h-24 w-full" (click)="acceptCookies()">J'accepte</div>
        <a
          href="{{ environment.publicUrl }}/politique-de-cookies/"
          target="_blank"
          class="text-15 mt-8"
        >
          Afficher toutes les utilisations prévues
        </a>
      </div>
    </div>
  </div>
</div>
