import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { BsRequest, RequestPayload } from 'src/app/shared/models/bs-request.model';
import { HydraCollection } from 'src/app/shared/models/hydra.model';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestService {
  constructor(private http: HttpClient) {}

  createRequest(data: Partial<RequestPayload>) {
    return this.http.post(`${environment.apiUrl}/requests`, data).pipe(
      map((res) => {
        return res as BsRequest;
      })
    );
  }

  query() {
    return this.http.get(`${environment.apiUrl}/users/me/requests`).pipe(
      map((res) => {
        res['hydra:member'].forEach((req) => {
          req.totalUnreadMessages = parseInt(req.totalUnreadMessages, 10);
        });
        return res;
      }),
      map((res: any) => {
        return res as HydraCollection<BsRequest>;
      })
    );
  }

  get(id: number) {
    return this.http.get(`${environment.apiUrl}/requests/${id}`).pipe(
      map((res) => {
        return res as BsRequest;
      })
    );
  }

  update(id: number, data: Partial<RequestPayload>) {
    return this.http.put(`${environment.apiUrl}/requests/${id}`, data).pipe(
      map((res) => {
        return res as BsRequest;
      })
    );
  }

  validate(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/merge-patch+json',
      }),
    };

    return this.http.patch(`${environment.apiUrl}/requests/${id}/validate`, {}, httpOptions).pipe(
      map((res) => {
        return res as BsRequest;
      })
    );
  }

  generateReport(id: number) {
    return this.http.post(
      `${environment.apiUrl}/requests/${id}/generate-report`,
      {},
      { responseType: 'blob' as 'json' }
    );
  }

  generateRecovery(id: number) {
    return this.http.post(
      `${environment.apiUrl}/requests/${id}/generate-option-content`,
      {},
      { responseType: 'blob' as 'json' }
    );
  }
}
