import { parseDateMember } from '../utils/parse-functions';

export class BsUser {
  id: number;
  email: string;
  fullName: string;
  isExecutive: boolean;
  phone: string;

  address: string;
  addressComplement: string;
  postalCode: string;
  city: string;

  gender: 'm' | 'f';
  dateOfBirth: Date;
  placeOfBirth: string;

  constructor(input) {
    parseDateMember(input, 'dateOfBirth');
    Object.assign(this, input);
  }
}
