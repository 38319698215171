import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from 'src/app/core/services/auth.service';
@Injectable({
  providedIn: 'root',
})
export class AuthenticateGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate() {
    if (this.authService.loggedUserValue) {
      return true;
    } else {
      this.router.navigate(['/user/login']);
      return false;
    }
  }
}
